import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Home from './Home';
import './App.css';
import Document from "./Document";
import ImportBill from "./Document/ImportBill";
import ActivationCode from "./Activity/ActivationCode";
import AppHomeActivationCode from "./Activity/AppHomeActivationCode";
import VipAgreement from "./VipAgreement";
import AuthAgreement from "./AuthAgreement";
import Privacy from "./Privacy/2024.09.23";
import UnderagePrivacy from "./Privacy/2024.09.23/UnderagePrivacy"
import Join from "./Join";
import RichPrivacy from "./RichPrivacy";
import Roadmap from "./Roadmap";
import RichVipAgreement from "./RichVipAgreement";
import Business from "./Business";
import Footer from "./Home/Footer";
import Header from "./Home/Header";
import {enquireScreen} from "enquire-js";
import AppHomeActivationCode1 from "./Activity/AppHomeActivationCode/index1";
import AndroidDownload from "./AndroidDownload";

let isMobile;

enquireScreen((b) => {
    isMobile = b;
});


class App extends Component {
    state = {
        isMobile,
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b,
            });
        });
    }

    render() {
        return (
            <Router>
                <div>
                    <Header isMobile={this.state.isMobile}/>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/privacy" component={Privacy}/>
                    <Route exact path="/rich_privacy" component={RichPrivacy}/>
                    <Route exact path="/underage_privacy" component={UnderagePrivacy}/>
                    <Route exact path="/activation_code_activity" component={ActivationCode}/>
                    <Route exact path="/document" component={Document}/>
                    <Route exact path="/document/import_bill" component={ImportBill}/>
                    <Route exact path="/roadmap" component={Roadmap}/>
                    <Route exact path="/auth_agreement" component={AuthAgreement}/>
                    <Route exact path="/vip_agreement" component={VipAgreement}/>
                    <Route exact path="/rich_vip_agreement" component={RichVipAgreement}/>
                    <Route exact path="/app_home_activation_code" component={AppHomeActivationCode}/>
                    <Route exact path="/app_home_activation_code_vip" component={AppHomeActivationCode1}/>
                    <Route exact path="/join" component={Join}/>
                    <Route exact path="/business" component={Business}/>
                    <Route exact path="/androidDownload" component={AndroidDownload}/>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

export default App;
